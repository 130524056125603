<template>
  <div class="flex-row-fluid ml-lg-12">
    <div class="card card-custom card-stretch">
      <div class="card-header py-3">
        <div class="card-title align-items-start flex-column">
          <h3 class="card-label font-weight-bolder text-dark">密码管理</h3>
          <span class="text-muted font-weight-bold font-size-sm mt-1">变更自己的密码</span>
        </div>
        <div class="card-toolbar">
          <button type="reset" class="btn btn-success" v-on:click="editPsd">保存</button>
        </div>
      </div>
      <div class="container" style="max-width: 70%">
        <form class="form">
          <div class="card-body">
            <div class="form-group row align-items-center">
              <div class="col-lg-12 col-xl-9">
                <div role="alert" v-if="errors && errors.length > 0" v-bind:class="{ show: errors.length }" class="alert fade alert-danger">
                  <div class="alert-text" v-for="(error, i) in errors" :key="i">
                    {{ error }}
                  </div>
                </div>
                <div role="alert" v-if="msgs && msgs.length > 0" v-bind:class="{ show: msgs.length }" class="alert fade alert-success">
                  <div class="alert-text" v-for="(msg, i) in msgs" :key="i">
                    {{ msg }}
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label"><span class="text-danger">*</span>手机号码：</label>
              <div class="col-lg-9 col-xl-6">
                <div class="input-group">
                  <input class="form-control" v-model="user.phone" type="text" placeholder="手机号码" name="phone" autocomplete="off" disabled/>
                  <div class="input-group-append">
                    <button type="button" class="btn btn-outline-secondary" v-on:click="validatePhone">发送</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label"><span class="text-danger">*</span>验证码：</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control" v-model="user.phoneCode" type="text" placeholder="验证码" name="phoneCode" autocomplete="off" />
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label"><span class="text-danger">*</span>新密码：</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control" v-model="newPassword" type="password" placeholder="新密码" name="phoneCode" autocomplete="new-password" />
              </div>
            </div>
            <div class="form-group row align-items-center">
              <label class="col-xl-3 col-lg-3 col-form-label"><span class="text-danger">*</span>确认密码：</label>
              <div class="col-lg-9 col-xl-6">
                <input class="form-control" v-model="confirmPsd" type="password" placeholder="确认密码" name="phoneCode" autocomplete="new-password" />
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import store from "@/core/services/store";
import {UPDATE_USER} from "@/core/services/store/auth.module";
export default {
  computed: {
    user: {
      get () {return this.$store.getters.currentUser;}
    }
  },
  data() {
    return {
      errors: [],
      msgs: [],
      newPassword: "",
      confirmPsd: "",
    };
  },
  methods: {
    validatePhone: function() {
      this.errors = [];
      this.msgs = [];
      if (this.user) {
        if (this.user.phone && this.user.phone.length === 11) {
          // 请求后台获取验证码
          this.$axios.fetchPost("/hr/user/validatePhone", this.user).then(data => {
            if (data.data && data.data.res === "noDb") {
              this.errors.push("企业平台错误");
            } else if (data.data && data.data.res === "exist") {
              this.errors.push("该手机号码已经被另外一个账号使用，请联系管理员");
            } else if (data.data && data.data.res === "success"){
              this.user.code = data.data.code;
              this.msgs.push("短信发送成功");
              return;
            }else {
              this.errors.push("请刷新重试");
            }
          }).catch(err => {
            console.log(err);
          });
        } else {
          this.errors.push("手机号码错误");
        }
      } else {
        this.errors.push("用户信息错误");
      }
    },
    editPsd() {
      this.errors = [];
      this.msgs = [];
      if (this.user && this.user.recordId){
        if (!this.newPassword){
          this.errors.push("请注意必须填写新密码");
          return;
        }
        if (!this.confirmPsd){
          this.errors.push("必须确认新密码");
          return;
        }
        if (this.newPassword !== this.confirmPsd) {
          this.errors.push("请确定两次密码的一致性");
          return;
        }
        if (!this.user.phoneCode){
          this.errors.push("请注意必须填写验证码");
          return;
        }
        this.user.password = this.newPassword;
        this.$axios.fetchPost("hr/user/editPwdLine",this.user).then(result => {
          if (result.data === "noDb") {
            this.errors.push("企业平台错误");
          } else if (result.data === "fail") {
            this.errors.push("短信验证码错误");
          } else if (result.data === "success") {
            this.newPassword = "";
            this.confirmPsd = "";
            this.msgs.push("密码修改成功！");
            store.dispatch(UPDATE_USER);
          } else {
            this.errors.push("请刷新重试");
          }
        }).catch(err => {console.log(err);});
      }else {
        this.errors.push("登录已过期，请刷新页面");
      }
    },
  }
}
</script>